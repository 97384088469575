import { IMethod } from './Method';

export enum MethodTypeEnum {
  AM = 'AM',
  CNC = 'CNC',
  MACHINING = 'machining',
  INJECTION_MOLDING = 'injection-molding',
}

export interface IMethodType {
  methods?: IMethod[];
  id: MethodTypeEnum;
}

type SupportFileTypeStruct = {
  [key in MethodTypeEnum]: string;
};

export const supportFileTypes: SupportFileTypeStruct = {
  [MethodTypeEnum.AM]: '.stp,.step,.igs,.iges,.stl,.obj,.3mf',
  [MethodTypeEnum.MACHINING]: '.iges,.igs,.stp,.step',
  [MethodTypeEnum.INJECTION_MOLDING]: '.stp,.step',
  [MethodTypeEnum.CNC]: '.dxf,.dwg',
};
