export enum OrderQuotationStatus {
  DRAFT = 'draft',
  FINALIZED = 'finalized',
  CANCELLED = 'cancelled',
  CUSTOMER_REVIEW_SENT = 'customer_review_sent',
  CHANGE_REQUESTED = 'change_requested',
}

export interface IOrderQuotation {
  id: number;
  status: OrderQuotationStatus;
  activities?: IOrderQuotationActivity[];
  latest_activity?: IOrderQuotationActivity;
  editable?: boolean;
  cancelable?: boolean;
  is_finalized?: boolean;
}

export interface IOrderQuotationActivity {
  created_at: string;
  customer_note?: string;
  status: OrderQuotationStatus;
  created_by?: string;
}
