import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import React, { useCallback } from 'react';

export enum Unit {
  SECOND = 's',
  MINUTE = 'min',
}

type Props = {
  unit: Unit;
  onUnitChanged?: (unit: Unit) => void;
};

export default function TimeUnitSelector({ unit, onUnitChanged = () => {} }: Props) {
  const onChange = useCallback(
    (selected: Unit) => {
      return () => onUnitChanged(selected);
    },
    [onUnitChanged],
  );

  return (
    <Box display="flex">
      {[Unit.SECOND, Unit.MINUTE].map((u) => {
        return (
          <Box marginRight={1} key={u}>
            <Button
              disableElevation
              disableRipple
              disableFocusRipple
              disableTouchRipple
              onClick={onChange(u as Unit)}
              variant={'contained'}
              color={unit === u ? 'primary' : 'inherit'}
            >
              {u}
            </Button>
          </Box>
        );
      })}
    </Box>
  );
}
