import React, { useCallback, useEffect, useMemo, useState } from 'react';
import CalculateIcon from '@mui/icons-material/Calculate';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import UnitSelector, { MassWeightUnit, Unit, UnitType } from '@v3/Components/UnitSelector';
import { ICurrency } from '@models/Currency';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TimeUnitSelector, { Unit as TimeUnit } from '@v3/Components/TimeUnitSelector';
import Divider from '@mui/material/Divider';
import { useForm } from '@inertiajs/react';

export enum CalculatorTypeEnum {
  SURFACE_AREA = 'surface-area-calculator',
  CUTTING_PRICE = 'cutting-price-calculator',
  CUTTING_SPEED = 'cutting-speed-calculator',
  MATERIAL_PRICE_MASS = 'material-price-mass-calculator',
}

type Props = {
  onSave: (newValue) => any;
  calculatorType: CalculatorTypeEnum;
  currency?: ICurrency;
};

export type CalculatorProps = Props;

// update when need calculator for other purpose
export default function Calculator({ onSave, calculatorType, currency }: Props) {
  const [finalValue, setFinalValue] = useState('0');
  const {
    data,
    setData,
    reset: resetForm,
  } = useForm({
    lengthUnit: Unit.MM,
    timeUnit: TimeUnit.SECOND,
    width: 0,
    height: 0,
    price: 0,
    velocity: 0,
    massUnit: MassWeightUnit.KILOGRAM,
  });
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleClose = useCallback(() => {
    setOpen(false);
    resetForm();
  }, [resetForm]);
  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const onSubmit = useCallback(() => {
    onSave(finalValue);
    handleClose();
  }, [finalValue, onSave]);

  const convertToMeters = useCallback((value, currentUnit) => {
    switch (currentUnit) {
      case Unit.MM:
        return value / 1000;
      case Unit.CM:
        return value / 100;
      case Unit.INCH:
        return value / 39.3701;
    }
    return value;
  }, []);

  const convertToMm = useCallback((value, currentUnit) => {
    switch (currentUnit) {
      case Unit.CM:
        return value * 10;
      case Unit.INCH:
        return value * 25.4;
    }
    return value;
  }, []);

  const convertToMmPerSecond = useCallback((value, lengthUnit, timeUnit) => {
    return convertToMm(value, lengthUnit) / (timeUnit === TimeUnit.MINUTE ? 60 : 1);
  }, []);

  const priceByKilogram = useCallback((value, currentUnit) => {
    switch (currentUnit) {
      case 'lb':
        return value / 2.205;
    }
    return value;
  }, []);

  useEffect(() => {
    let finalCalculation = '0';
    switch (calculatorType) {
      case CalculatorTypeEnum.SURFACE_AREA:
        if (data.price > 0 && data.height > 0 && data.width > 0) {
          finalCalculation = (
            data.price /
            (convertToMeters(data.height, data.lengthUnit) * convertToMeters(data.width, data.lengthUnit))
          ).toFixed(currency.precision);
        }
        break;
      case CalculatorTypeEnum.CUTTING_SPEED:
        if (data.velocity > 0) {
          finalCalculation = convertToMmPerSecond(data.velocity, data.lengthUnit, data.timeUnit).toFixed(2);
        }
        break;
      case CalculatorTypeEnum.CUTTING_PRICE:
        if (data.price > 0) {
          finalCalculation = (data.price / convertToMeters(1, data.lengthUnit)).toFixed(currency.precision);
        }
        break;
      case CalculatorTypeEnum.MATERIAL_PRICE_MASS:
        if (data.price > 0) {
          finalCalculation = priceByKilogram(data.price, data.massUnit).toFixed(currency.precision);
        }
    }
    setFinalValue(finalCalculation);
  }, [data]);

  const onLengthUnitChange = useCallback(
    (selectedUnit) => {
      setData('lengthUnit', selectedUnit);
    },
    [setData],
  );

  const onTimeUnitChange = useCallback(
    (selectedUnit) => {
      setData('timeUnit', selectedUnit);
    },
    [setData],
  );

  const onMassUnitChange = useCallback(
    (selectedUnit) => {
      setData('massUnit', selectedUnit);
    },
    [setData],
  );

  const onChange = useCallback(
    (e) => {
      const v = e.target.value;
      setData(e.target.name, !v ? v : parseFloat(v));
    },
    [setData],
  );

  const output = useMemo(() => {
    switch (calculatorType) {
      case CalculatorTypeEnum.SURFACE_AREA:
        return `${currency.icon}${finalValue} ${currency.code}/m\u00B2`;
      case CalculatorTypeEnum.CUTTING_SPEED:
        return `${finalValue} mm/s`;
      case CalculatorTypeEnum.CUTTING_PRICE:
        return `${currency.icon}${finalValue} ${currency.code}/m`;
      case CalculatorTypeEnum.MATERIAL_PRICE_MASS:
        return `${currency.icon}${finalValue} ${currency.code}/kg`;
      default:
        return '';
    }
  }, [finalValue]);

  return (
    <>
      <IconButton color={'primary'} size={'small'} onClick={handleOpen}>
        <CalculateIcon fontSize={'small'} />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {t(`calculator.${calculatorType}.title`)}
          {handleClose ? (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent>
          <Typography gutterBottom sx={{ mb: 2 }}>
            {t(`calculator.${calculatorType}.description`, { currencyIcon: currency.icon })}
          </Typography>
          {calculatorType === CalculatorTypeEnum.SURFACE_AREA && (
            <Box>
              <UnitSelector unit={data.lengthUnit} onUnitChanged={onLengthUnitChange} hideTitle />
              <Box alignItems={'center'} mt={3.5}>
                <Grid container spacing={2} sx={{ alignItems: 'center' }}>
                  <Grid item xs>
                    <TextField label={'Height'} variant={'outlined'} onChange={onChange} name={'height'} fullWidth />
                  </Grid>
                  <Grid item xs>
                    <TextField
                      label={'Width'}
                      variant={'outlined'}
                      onChange={onChange}
                      name={'width'}
                      fullWidth
                      sx={{ marginBottom: 2 }}
                    />
                    <img src={'/images/height-width.png'} alt={'height-width'} />
                  </Grid>
                  <Grid item xs>
                    <TextField label={'Price'} variant={'outlined'} onChange={onChange} name={'price'} fullWidth />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          )}
          {calculatorType === CalculatorTypeEnum.CUTTING_SPEED && (
            <Box alignItems={'center'}>
              <Grid container spacing={3} sx={{ alignItems: 'center' }}>
                <Grid item xs={6} alignItems={'center'} justifyContent={'flex-end'}>
                  <TextField label={'Velocity'} name={'velocity'} variant={'outlined'} onChange={onChange} fullWidth />
                </Grid>
                <Grid item xs={6} alignItems={'center'}>
                  <UnitSelector unit={data.lengthUnit} onUnitChanged={onLengthUnitChange} hideTitle />
                  <Divider sx={{ my: 2 }} />
                  <TimeUnitSelector unit={data.timeUnit} onUnitChanged={onTimeUnitChange} />
                </Grid>
              </Grid>
            </Box>
          )}
          {calculatorType === CalculatorTypeEnum.CUTTING_PRICE && (
            <Box alignItems={'center'} justifyContent={'center'}>
              <TextField
                label={'Price'}
                variant={'outlined'}
                onChange={onChange}
                name={'price'}
                fullWidth
                sx={{ mb: 2 }}
              />
              <UnitSelector
                containerProps={{ display: 'flex', justifyContent: 'center' }}
                unit={data.lengthUnit}
                onUnitChanged={onLengthUnitChange}
                hideTitle
              />
            </Box>
          )}
          {calculatorType === CalculatorTypeEnum.MATERIAL_PRICE_MASS && (
            <Box alignItems={'center'} justifyContent={'center'}>
              <TextField
                label={'Price'}
                variant={'outlined'}
                onChange={onChange}
                name={'price'}
                fullWidth
                sx={{ mb: 2 }}
              />
              <UnitSelector
                containerProps={{ display: 'flex', justifyContent: 'center' }}
                unit={data.massUnit}
                onUnitChanged={onMassUnitChange}
                hideTitle
                unitType={UnitType.WEIGHT}
              />
            </Box>
          )}
          <Box display={'flex'} justifyContent={'center'} marginTop={2}>
            <Typography variant={'h6'} gutterBottom>
              {output}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color={'primary'} onClick={onSubmit} variant={'contained'}>
            Submit Value
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
