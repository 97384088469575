import { Unit } from '@v3/Components/UnitSelector';

export type PartnerSecondaryProcess = {
  machine?: MachineInfo;
  pricing_method?: string;
  setup_fee?: string;
  pricing_value?: string;
};

export type MachineInfo = {
  make?: string;
  model?: string;
  max_tonnage?: string;
  max_length?: string;
  length_unit?: Unit;
};

export namespace SecondaryProcessPriceMethod {
  // e.g. price per bend
  export const PRICE_PER_METHOD_TYPE = 'price_per_method_type';
  // e.g. price per bend length
  export const PRICE_PER_LENGTH = 'price_per_length';
  export const ORDER_PERCENTAGE = 'order_percentage';
}

export enum SecondaryProcessTypeEnum {
  CNC_PRESS_BRAKE = 'cnc_press_brake',
  CNC_COUNTERSINK = 'cnc_countersink',
  CNC_COUNTERBORE = 'cnc_counterbore',
}
