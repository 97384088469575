import { IMachiningPart } from './Machining/Part';
import { MethodTypeEnum } from './MethodType';
import { IPartner } from './Partner';
import { IAMPart } from './AM/Part';
import { IMethod } from '@models/Method';
import { IUser } from '@models/User';
import { IInvoice } from '@models/Invoice';
import { IPremiumOrderMetaData } from '@models/PremiumOrderMetaData';
import { IOrderQuotation } from '@models/OrderQuotation';

export type IOrderSinglePart = IAMPart &
  IMachiningPart & {
    renderable_url?: string;
  };

export type IOrderParts = Array<IOrderSinglePart>;

export interface IOrder {
  id: number;
  friendly_id: string;
  name?: string;
  location: string;
  method_id: number;
  currency_id: number;
  partner: IPartner;
  is_exclusive: boolean;
  is_referral: boolean;
  is_plugin: boolean;
  uuid: string;
  parts: IOrderParts;
  type: MethodTypeEnum;
  method: IMethod;
  premium_order_metadata?: IPremiumOrderMetaData;
  stage: number;
  stepper_stage: number;
  user: IUser;
  invoice: IInvoice;
  is_rfq: boolean;
  is_collecting_tax: boolean;
  manufacturing_lead_time: number;
  lead_time_calculation_mode: string;
  expected_shipping_date?: string;
  customer_line_items_notes?: any;
  quotation?: IOrderQuotation;
  export_controlled?: boolean;
}

export enum OrderProgress {
  PENDING_PARTNER_APPROVAL = 2,
  PENDING_PAYMENT = 3,
  MANUFACTURING = 4,
  SHIPPED = 5,
  IN_REVIEW = 6,
  COMPLETED = 7,
}
