import createTheme from '@mui/material/styles/createTheme';
import { red } from '@mui/material/colors';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#E0592C',
    },
    secondary: {
      main: '#c9542c',
    },
  },
  typography: {
    fontFamily: 'Inter, arial, sans-serif',
    fontSize: 14,
    h1: {
      fontSize: 64,
      fontWeight: 700,
    },
    h2: {
      fontSize: 40,
      fontWeight: 700,
    },
    h3: {
      fontSize: 28,
      fontWethemeight: 700,
    },
    h4: {
      fontSize: 28,
      fontWeight: 700,
    },
    h5: {
      fontSize: 24,
      fontWeight: 700,
    },
    h6: {
      fontSize: 20,
      fontWeight: 700,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          '.pac-container': {
            zIndex: 1301,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 600,
        },
      },
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiCardActionArea: {
      styleOverrides: {
        root: {
          '&:hover .MuiCardActionArea-focusHighlight': {
            opacity: 0.01,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
        },
      },
    },
    MuiCard: {
      defaultProps: {
        elevation: 0,
        variant: 'outlined',
      },
    },
    MuiTableRow: {
      styleOverrides: {
        hover: {
          cursor: 'pointer',
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        fullWidth: true,
        maxWidth: 'md',
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
      },
    },
  },
});

export const partnerTheme = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    primary: {
      main: '#006cff',
    },
    secondary: {
      main: '#006cff',
    },
  },
});

export const partnerFooterTheme = createTheme({
  ...partnerTheme,
  palette: {
    ...partnerTheme.palette,
    background: {
      default: '#004cb3',
    },
    text: {
      primary: '#fff',
    },
  },
});

export const errorTheme = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    primary: {
      main: red[500],
    },
  },
});
