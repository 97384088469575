import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { OrderProgress } from '@models/Order';
import { MethodTypeEnum } from '@models/MethodType';
import { UserAnalyticSourceData, UserRoles, UserStatus } from '@models/User';
import { CalculatorTypeEnum } from '@v3/Components/Calculator';
import { FeeMethod } from '@models/Method';
import { SecondaryProcessPriceMethod, SecondaryProcessTypeEnum } from '@v3/Types/SecondaryProcess/secondaryProcess';
import { OrderQuotationStatus } from '@models/OrderQuotation';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      materialPricingMethod: {
        material_price: 'Material Price',
        pierce_time: 'Pierce Time',
        pierce_price: 'Pierce Price',
        cutting_speed: 'Cutting Speed',
        cutting_price: 'Cutting Price',
      },
      feeMethod: {
        min_order_price: 'Minimum Order Price',
        min_order_price_explain:
          'When the Total Order Price (Machine Pricing Method value + Machine Setup Price value + Post Processing value) is calculated to be less than the Minimum Order Price, the Minimum Order Price will be displayed to the Customer.',
        min_handling_fee: 'Machine Setup Price (Minimum)',
        min_handling_fee_explain:
          'The Total Order Price is made up of 3 parts: Machine Pricing Method value + Machine Setup Price value + Post Processing value. The Machine Setup Price is used to set a base price for setting up your machine and may include any extra handling and setup fees.',
        handling_fee: 'Machine Setup Price (Order %)',
        handling_fee_explain:
          'When the calculated Machine Setup Price value as a function of Order % is higher than the set Machine Setup Price value, the Machine Setup Price value will be calculated using the set % of the Machine Pricing Method Value.',
        operating_price: 'Hourly Machine Rate',
        operating_price_explain:
          'This is the Hourly Machine Rate used in the Machine Time & Materials Machine Pricing Method.',
      },
      customOrderPageForm: {
        send_order_request: {
          blockTitle: 'Send Order Request Page',
          blockDescription: 'Users will see this page when they review their order before submitting.',
        },
        order_request_sent: {
          blockTitle: 'Order Request Sent Page',
          blockDescription: 'Users will see this page after the order is submitted.',
        },
        order_pending_approval: {
          blockTitle: 'Order Pending Approval Page',
          blockDescription: 'Users will see this page when the order is pending approval.',
        },
        order_pending_payment: {
          blockTitle: 'Order Pending Payment Page',
          blockDescription: 'Users will see this page when the order is pending payment.',
        },
        order_manufacturing: {
          blockTitle: 'Order Manufacturing Page',
          blockDescription: 'Users will see this page when the order is being manufactured.',
        },
        order_ready_for_pickup: {
          blockTitle: 'Order Ready for Pickup Page',
          blockDescription: 'Users will see this page when the order is ready for pickup.',
        },
        order_shipped: {
          blockTitle: 'Order Shipped Page',
          blockDescription: 'Users will see this page when the order is shipped.',
        },
        order_completed: {
          blockTitle: 'Order Completed Page',
          blockDescription: 'Users will see this page when the order is completed.',
        },
      },
      partsCount_plural: '{{count}} parts',
      partsCount: '{{count}} part',
      ordersCount_plural: '{{count}} orders',
      ordersCount: '{{count}} order',
      itemsCount_plural: '{{count}} items',
      itemsCount: '{{count}} item',
      'Select a AM method': 'Select a 3D Printing method',
      'Select a CNC method': 'Select a CNC Cutting method',
      'Select a machining method': 'Select a CNC Machining method',
      'Select a injection-molding method': 'Select an Injection Molding method',
      methodType: {
        AM: '3D Printing',
        CNC: 'CNC Cutting',
        machining: 'CNC Machining',
        [MethodTypeEnum.INJECTION_MOLDING]: 'Injection Molding',
      },
      methodTypeV2: {
        AM: '3D Printing',
        CNC: 'Sheet Metal',
        machining: 'Machining',
        [MethodTypeEnum.INJECTION_MOLDING]: 'Injection Molding',
      },
      methodTypeUrl: {
        AM: 'https://prototypehubs.com/3d-printing/#3d-printing-methods',
        machining: 'https://prototypehubs.com/cnc-machining/',
      },
      knowledgeBaseUrl: {
        AM: 'https://prototypehubs.com/accepted-3d-printing-files/',
        CNC: 'https://prototypehubs.com/prototype-hubs-platform/cnc-cutting-prototype-hubs-platform/accepted-cnc-cutting-files/',
        machining: 'https://prototypehubs.com/accepted-cnc-machining-files/',
        'injection-molding': 'https://prototypehubs.com/knowledge-base/',
      },
      sampleFileUrl: {
        AM: 'https://prototypehubs.com/app/themes/prototype/images/sioux-falls-cathedral.stl',
        CNC: 'https://prototypehubs.com/accepted-cnc-machining-files/',
        machining: 'https://s3.amazonaws.com/wp.prototypehubs.com/TITAN-18M.stp',
        'injection-molding': 'https://s3.amazonaws.com/wp.prototypehubs.com/Injection-Molded-Wheel.STEP',
      },
      acceptFileType: {
        [MethodTypeEnum.AM]: 'STL, 3MF, OBJ, IGES and STEP',
        [MethodTypeEnum.CNC]: 'DXF or DWG',
        [MethodTypeEnum.MACHINING]: 'IGES or STEP',
        [MethodTypeEnum.INJECTION_MOLDING]: 'STEP or STP',
      },
      shippingMethod: {
        express_shipping: 'Express Shipping',
        standard_delivery: 'Standard Delivery',
        international_shipping: 'International Shipping',
        pickup: 'Pickup',
      },
      shippingMethodSymbol: {
        express_shipping: '$$',
        standard_delivery: '$$',
        international_shipping: '$$',
        pickup: 'FREE',
      },
      mpQuotationDetailPage: {
        [OrderQuotationStatus.DRAFT]: {
          title: 'Draft Quote',
          description:
            'This Quote is currently in a Draft state and has not been sent to the\n' +
            'Customer for review. To send this Quote to the Customer, please click on the "Send for Review"\n' +
            'button.',
        },
        [OrderQuotationStatus.FINALIZED]: {
          title: 'Quotation Finalized',
          description: 'Description Goes Here',
        },
        [OrderQuotationStatus.CANCELLED]: {
          title: 'Quote Cancelled',
          description:
            'This Quote has been Cancelled and is no longer available. Once a\n' +
            'Quote has been Cancelled, it is no longer viewable online by the Customer.',
        },
        [OrderQuotationStatus.CUSTOMER_REVIEW_SENT]: {
          title: 'Quote Sent for Customer Review',
          description:
            'This Quote has been sent to the Customer for review. A Quote in\n' +
            'PDF format as well as a link to view the Quote Online has been sent to the Customer.',
        },
        [OrderQuotationStatus.CHANGE_REQUESTED]: {
          title: 'Quote Changes Requested',
          description:
            'The Customer has reviewed the Quote and is requesting changes. Please review the changes below or by clicking on the Quote Activity button, apply the changes to the Quote, and Resend for Review to the Customer.',
        },
      },
      quoteStatus: {
        [OrderQuotationStatus.DRAFT]: 'Draft',
        [OrderQuotationStatus.CHANGE_REQUESTED]: 'Changes Requested',
        [OrderQuotationStatus.CUSTOMER_REVIEW_SENT]: 'Customer Review',
        [OrderQuotationStatus.CANCELLED]: 'Cancelled',
        [OrderQuotationStatus.FINALIZED]: 'Finalized',
      },
      mpOrderDetailPage: {
        [OrderProgress.PENDING_PARTNER_APPROVAL]: {
          title: 'Awaiting Your Order Response',
          description:
            'A new Order Request has been submitted by {{user.name}}. Please review the Order Request details, and enter the Shipping Price if applicable. If any picking adjustments are made to the order that would change the total Order Price please make sure to contact the Customer to explain the changes and leave a comment in the notes field below',
        },
        [OrderProgress.PENDING_PAYMENT]: {
          title: 'Awaiting Payment from {{user.name}}',
          description:
            'This Order Response is currently waiting for payment from the Customer. Once the Order Price has been paid, the order status will automatically switch to <i>Manufacturing</i> and you can begin production.',
        },
        [OrderProgress.MANUFACTURING]: {
          title: 'Manufacturing Progress',
          description:
            'Payment for this order has been received and production can begin. Once all of the parts have been manufactured and shipped to the Customer, you must update the order status to <i>Shipped</i> and the Customer will receive a notification that they can expect their order soon.',
        },
        [OrderProgress.SHIPPED]: {
          title: 'Order Has Been Shipped',
          description:
            'This Order has been shipped to {{user.name}} but has not yet been marked as Completed. If you feel the Customer should have received the order, please feel free to follow up with them directly. After 21 days with no response from the Customer, the order will automatically be marked as Completed.',
        },
        [OrderProgress.COMPLETED]: {
          title: 'Order Completed',
          description: 'This order has been completed and received by {{user.name}}',
        },
        [OrderProgress.IN_REVIEW]: {
          title: 'Checking Order for Possible Issues',
          description:
            'This order is currently being reviewed by the Prototype Hubs Customer Support Team. Please <a href="https://prototypehubs.com/customer-support/" target="_blank">contact us</a> directly if you have any questions or concerns.',
        },
        shipping_price_change_no_notes:
          'We noticed that you have made some changes to the shipping price or services, please provide details about these changes in the Additional Notes section.',
        shipping_method: {
          pickup:
            'The Customer has chosen to pick up this Order rather than having it shipped by a carrier service. Please coordinate with the customer using the online messaging system to arrange pick up details.',
          manual_shipping_quote:
            'The Customer has requested a manual shipping quote to be added to this Order. This option may have been chosen because no automated shipping estimates were available, the automated shipping estimates were not perceived to be correct, or the Customer requires an option that was not listed (LTL Freight, etc.). Please add a shipping price below, along with the carrier + service and then click apply. This will add the manual shipping quote to the Order Response for the customer to see.',
          custom_options:
            'This Custom Shipping option is available to use when none of the below options apply. Please add a shipping price below, along with a carrier + service and then click apply. This will add the Custom Shipping quote to the Order Response for the customer to see.',
          default:
            'The customer has chosen this automated shipping estimate from a list of provided shipping estimates during the Order Request process. Either accept this automated shipping estimate as it is, edit the shipping price, or select a Custom Shipping option below and then set the shipping price along with a carrier + service. Although the customer has chosen this specific option, you may substitute the carrier for a different carrier using an equivalent service interval by choosing the Custom Shipping option below.',
        },
        part_price_info: {
          quantity: 'Quantity',
          unit: 'Unit',
          pricing_method: {
            label: 'Pricing Method',
            description: {
              [FeeMethod.SMART_PRICE]: 'Smart Price',
              [FeeMethod.MACHINE_TIME_MATERIALS]: 'Machine Time & Materials',
              [FeeMethod.PRICED_PROCESS]: 'Priced Process & Materials',
              [FeeMethod.MATERIAL_PRICE]: 'Material Price',
            },
          },
          machine_setup_method: 'Machine Setup Method',
          post_processing_method: {
            label: 'Post Processing Method',
            description: {
              minimum: 'Minimum',
              order_percentage: 'Order Percentage',
              surface_area: 'Surface Area',
            },
          },
          part_price_total: '{{prefixLabel}} (Total)',
          machine_setup_price: 'Machine Setup Price',
          post_processing_fee: 'Post Processing Price (Total):',
          total_price_before_discount: 'Total Price (Before Discount)',
          discount_amount: 'Discount Amount (Total)',
          total_price: 'Total Price',
          total_cut_length: 'Total Cut Length (Each)',
          part_surface_area: {
            label: 'Part Surface Area (Each)',
            tooltip:
              'The calculated Part Surface Area is for only one side of the part. The Post Processing Price uses double the calculated Part Surface Area.',
          },
          post_processing_price: 'Post Processing Price',
          formulaVariableName: {
            operating_price: 'Hourly Machine Rate',
            raw_material_price: 'Material Price',
            total_print_time: 'Part Print Time (Each)',
            object_material: 'Object Material (g)',
            support_material: 'Support Material',
            object_volume: 'Part Material Volume (Each)',
            support_volume: 'Support Material Volume (Each)',
            support_material_price: 'Support Material Price',
            pierce_time: 'Pierce Time',
            pierce_lines: 'Total Pierces (Each)',
            pierce_price: 'Pierce Price',
            total_cut_length: 'Total Cut Length (Each)',
            cutting_speed: 'Cutting Speed',
            cutting_price: 'Cutting Price',
            bounding_box_area: 'Bounding Box Area (Each)',
          },
        },
        manufacturing_lead_time:
          'Please enter the estimated number of production days needed to complete this order. Production days are counted from the time the Order is paid for (or ' +
          'moved into a status of Manufacturing) until the order has been marked as ' +
          'Shipped (or Ready for Pickup). ',
      },
      quote: {
        min_order_price_global:
          'The Total Price is below the Prototype Hubs Minimum Order Price of {{price}}, therefore the Prototype Hubs Minimum Order Price has been applied. Increase the quantity of your parts or add more parts to your Quote.',
        min_order_price_mp:
          'The Total Price is below the Manufacturing Partner’s Minimum Order Price of {{price}}, therefore the Manufacturing Partner’s Minimum Order Price has been applied. Increase the quantity of your parts or add more parts to your Quote.',
        discount_type: {
          first_order: {
            description: '5% First Order Discount',
          },
          forge_discount: {
            description: '5% The Forge Discount',
          },
        },
      },
      partner: {
        fees_discount_promotion:
          'Upgrade to a Premium Manufacturing Partner Account to remove the Order Fixed Transaction Fee and lower your commission fees by an additional 4%.',
        post_process: {
          title: {
            [MethodTypeEnum.AM]: 'Edit 3D Printing Post Processes',
            [MethodTypeEnum.CNC]: 'Edit CNC Cutting Post Processes',
            [MethodTypeEnum.INJECTION_MOLDING]: 'Edit Injection Molding Post Processes',
          },
          pricing_method: {
            order_percentage: 'Order Percentage',
            surface_area: 'Surface Area',
          },
        },
        secondary_process: {
          [SecondaryProcessTypeEnum.CNC_PRESS_BRAKE]: {
            fee: {
              [SecondaryProcessPriceMethod.PRICE_PER_METHOD_TYPE]: '{{currency}}/Bend',
              [SecondaryProcessPriceMethod.PRICE_PER_LENGTH]: '{{currency}}/Bend Length',
              [SecondaryProcessPriceMethod.ORDER_PERCENTAGE]: 'Order Percentage',
            },
          },
          [SecondaryProcessTypeEnum.CNC_COUNTERSINK]: {
            title: 'Countersink',
            fee: {
              [SecondaryProcessPriceMethod.PRICE_PER_METHOD_TYPE]: '{{currency}}/Countersink',
            },
          },
          [SecondaryProcessTypeEnum.CNC_COUNTERBORE]: {
            title: 'Counterbore',
            fee: {
              [SecondaryProcessPriceMethod.PRICE_PER_METHOD_TYPE]: '{{currency}}/Counterbore',
            },
          },
        },
        machine: {
          [MethodTypeEnum.INJECTION_MOLDING]: {
            price_method: {
              [FeeMethod.AUTOMATED_RFQ]: {
                title: 'Smart RFQ',
                icon: '/images/icons/ic_smart-rfq.svg',
              },
              [FeeMethod.AUTOMATED_PRICING]: {
                title: 'Automated Pricing',
                icon: '/images/method_type_icons/injection-molding-icon.svg',
              },
              descriptor: {
                minimum_order_price:
                  // eslint-disable-next-line max-len
                  'This is the minimum amount that an Order will be charged to the Customer. If the Order Price is calculated to be less than the Minimum Order Price, the Minimum Order Price will be the amount that the customer will be required to pay.',
                general_administrative_percentage:
                  // eslint-disable-next-line max-len
                  'This is the amount, in terms of a percentage, of the Order Price (before profit) that is set aside for General & Administrative (G&A) costs for the order.',
                machine_setup_price:
                  'This is the one time fee that is added to the Sub-Total of the order for setting up the machine.',
                hourly_machine_rate:
                  // eslint-disable-next-line max-len
                  'This is the hourly rate to run the injection molding machine. Note that this does not include Labor.',
                machine_up_time_percentage:
                  'The Machine Up Time is used to calculate the Net Parts/Hour for the machine.',
                number_of_operators:
                  'This is the amount of operators it takes to run the machine during the injection molding process.',
                labor_rate:
                  // eslint-disable-next-line max-len
                  'This is hourly rate at which the operator(s) of the machine are paid during the injection molding process.',
                profit_percentage:
                  // eslint-disable-next-line max-len
                  'This is the amount, in terms of a percentage, of the Total Order Price that is set aside as Profit for the order.',
                scrap_percentage:
                  'This is the amount, in terms of a percentage, of the Order that is calculated as Scrap.',
              },
            },
          },
          method_configuration: {
            require_drawing_label: {
              plugin: 'Require drawings for all ManufactureOS™ {{method_type}} orders.',
              marketplace: 'Require drawings for all {{method_type}} orders',
            },
          },
        },
      },
      userStatus: {
        [UserStatus.ACTIVE]: 'Active account',
        [UserStatus.ONBOARDING]: 'Onboarding account',
        [UserStatus.INVITED]: 'Account invite sent',
        [UserStatus.INACTIVE]: 'Inactive account',
      },
      userStatusExplanation: {
        [UserStatus.ACTIVE]:
          'This customer has a registered account and has completed the necessary onboarding steps to have a fully completed account.',
        [UserStatus.ONBOARDING]:
          'This customer has filled out the lead capture information but has not completed their account sign up, Or; This customer has clicked on the account invite email link but did not finish the sign up process.',
        [UserStatus.INVITED]:
          'This customer was created by the manufacturer and a sign up request has been sent. The customer has has not accepted the request and completed the account sign up requirements.',
        [UserStatus.INACTIVE]:
          'This customer was created by the manufacturer but has not been sent an invitation request to sign up yet.',
      },
      userSource: {
        [UserAnalyticSourceData.REGISTRATION_FORM]: 'Registered Customer',
        [UserAnalyticSourceData.LEAD_FORM]: 'Customer Lead Sign Up',
        [UserAnalyticSourceData.INVITED]: 'Created by Manufacturer ({{staff_name}})',
      },
      userSourceExplanation: {
        [UserAnalyticSourceData.REGISTRATION_FORM]:
          'This customer has signed up and created an account to get a quote, place an Order Request, or use the chat feature to communicate with you.',
        [UserAnalyticSourceData.LEAD_FORM]:
          'This customer has filled out the information in the lead capture form, but has not signed up for an account yet.',
      },
      userRole: {
        [UserRoles.OWNER]: 'Owner',
        [UserRoles.ADMIN]: 'Admin',
        [UserRoles.QUOTING]: 'Quoting',
        [UserRoles.SHOP_HAND]: 'Shop Hand',
      },
      sortKey: {
        customers: {
          last_update: 'Last updated',
          date_added: 'Date added',
          email: 'Email address',
          first_name: 'First name',
          last_name: 'Last name',
        },
        teamMembers: {
          last_update: 'Last updated',
          date_added: 'Date added',
          email: 'Email address',
          first_name: 'First name',
          last_name: 'Last name',
        },
      },
      calculator: {
        [CalculatorTypeEnum.SURFACE_AREA]: {
          title: 'Surface Area Calculator',
          description:
            'The surface area calculator is a simple tool to help enter the correct value needed by the Prototype Hubs quoting software. To use it, please choose your units and enter a width, height, and price into the input boxes. Once you have finished, you may click on the Submit Value button. All results will be converted to {{currencyIcon}}/m² and stored in the database.',
        },
        [CalculatorTypeEnum.CUTTING_PRICE]: {
          title: 'Cutting Price Calculator',
          description:
            'The cutting price calculator is a simple tool to help enter the correct value needed by the Prototype Hubs quoting software. To use it, please choose your units and enter a price per unit of cut line length into the input box. Once you have finished, you may click on the Submit Value button. All results will be converted to {{currencyIcon}}/m and stored in the database.',
        },
        [CalculatorTypeEnum.CUTTING_SPEED]: {
          title: 'Cutting Speed Calculator',
          description:
            'The cutting speed calculator is a simple tool to help enter the correct value needed by the Prototype Hubs quoting software. To use it, please choose your units and enter a cutting speed in the input box. Once you have finished, you may click on the Submit Value button. All results will be converted to mm/s and stored in the database.',
        },
        [CalculatorTypeEnum.MATERIAL_PRICE_MASS]: {
          title: 'Material Price Calculator',
          description:
            ' The material price calculator is a simple tool to help enter the correct value needed by the Prototype Hubs quoting software. To use it, please choose your units and enter a price per unit of mass into the input box. Once you have finished, you may click on the Submit Value button. All results will be converted to $/kg and stored in the database.',
        },
      },
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
