import type { Address } from '@models/Address';

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  ONBOARDING = 'ONBOARDING',
  INACTIVE = 'INACTIVE',
  INVITED = 'INVITED',
}

export enum UserRoles {
  OWNER = 'owner',
  ADMIN = 'admin',
  QUOTING = 'quoting',
  SHOP_HAND = 'shop-hand',
}

export enum UserAnalyticSourceData {
  REGISTRATION_FORM = 'REGISTRATION_FORM',
  LEAD_FORM = 'LEAD_FORM',
  INVITED = 'INVITED',
}

export interface IUser {
  email: string;
  id: number;
  name: string;
  first_name: string;
  last_name: string;
  formatted_address: string;
  currency_id: number;
  profile_picture_url?: string;
  shipping_address?: Address;
  mailing_address?: Address;
  analytic_source_data?: UserAnalyticSourceData;
  created_by?: string;
  url?: string;
}
