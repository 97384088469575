import Button from '@mui/material/Button';
import Box, { BoxProps } from '@mui/material/Box';
import React, { useCallback, useMemo } from 'react';
import { SectionTitle } from '@components/layouts/partner/SectionTitle';

export enum Unit {
  MM = 'mm',
  CM = 'cm',
  INCH = 'in',
}

export enum MassWeightUnit {
  LB = 'lb',
  KILOGRAM = 'kg',
}

export enum UnitType {
  LENGTH = 'length',
  WEIGHT = 'weight',
}

type Props = {
  // set the optional unit either Unit or MassWeightUnit
  unit: Unit | MassWeightUnit;
  onUnitChanged?: (unit: Unit | MassWeightUnit) => void;
  hideTitle?: boolean;
  containerProps?: BoxProps;
  unitType?: UnitType;
};

export default function UnitSelector({
  unit,
  onUnitChanged = () => {},
  hideTitle = false,
  containerProps,
  unitType,
}: Props) {
  const onChange = useCallback(
    (selected: Unit | MassWeightUnit) => {
      return () => onUnitChanged(selected);
    },
    [onUnitChanged],
  );

  const selectedUnit = useMemo(() => {
    return unitType === UnitType.WEIGHT ? [MassWeightUnit.LB, MassWeightUnit.KILOGRAM] : [Unit.INCH, Unit.CM, Unit.MM];
  }, [unitType]);

  return (
    <Box {...containerProps}>
      {!hideTitle && <SectionTitle>Units</SectionTitle>}
      <Box display="flex">
        {selectedUnit.map((u) => {
          return (
            <Box marginRight={1} key={u}>
              <Button
                disableElevation
                disableRipple
                disableFocusRipple
                disableTouchRipple
                onClick={onChange(u as Unit | MassWeightUnit)}
                variant={'contained'}
                color={unit === u ? 'primary' : 'inherit'}
              >
                {u}
              </Button>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
